.header {
  position: relative;
  font-size: 18px;
  z-index: 999;
}

#main {
  background-color: #f7f7f7;
}

.header__background {
  position: fixed;
  width: 100%;
  height: 84px;
  padding: 18px 30px;
  transition: all 300ms;
  border-bottom: 1px solid transparent;
}
.header__background--scroll {
  background-color: #3a5062;
  top: 0;
  box-shadow: 0 16px 22px -9px rgb(93 111 119 / 6%);
  border-bottom-color: #b8c7cd;
}
.header__wrapper {
  width: 100%;
  max-width: 1170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.header__logo {
  color: #fff;
  text-decoration: none;
  transition: color 300ms;
}
.header__nav {
  display: none;
}
@media screen and (min-width: 851px) {
  .header__right {
    width: 100%;
  }
  .header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header__nav-list {
    align-items: center;
    justify-content: flex-start;
  }
  .header__nav-list li {
    margin-left: 40px;
  }
}

.header__nav-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.header__navlink {
  color: #b8c7cd;
  text-decoration: none;
  transition: all 300ms;
}
.header__navlink:hover,
.header__navlink.active {
  text-decoration: none;
  color: #fff;
}

section.section.section_main {
  color: #fff;
  font-size: 20px;
  background-color: #3a5062;
}

section.section {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
section.section .section__wrapper {
  /* max-width: 1230px; */
  /* padding: 0 30px; */
  box-sizing: border-box;
  margin: auto;
  position: relative;
  overflow: hidden;
}
section.section.section_main .section__wrapper {
  padding-top: 105px;
}
.main-ip-info__ip {
  display: inline !important;
  text-align: center !important;
}
.container_main-ip-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container_main-ip-info .main-ip-info__ip {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 20px;
}
.main-ip-info__ip h1 {
  display: inline !important;
}
section.section.section_main h1 {
  font-weight: 500;
  margin-left: 1px;
}
.main-ip-info__ip .your-ip {
  display: inline !important;
  margin-left: 0 !important;
}
.main-ip-info__ip-data.row {
  justify-content: space-between;
}
.main-ip-info__ip-data {
  max-width: 1200px;
  width: 100%;
}
.main-ip-info__ip-data .col-md-6 {
  padding: 0;
  max-width: 540px;
  overflow: hidden;
}
.icon.icon_sm {
  display: block;
  text-align: center;
  width: 20px;
  margin-right: 10px;
}
.icon.icon_sm img {
  max-width: 20px;
}
.main-ip-info__ip-data .ip-data__row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  height: 50px;
  white-space: nowrap;
  padding-top: 17px;
  padding-bottom: 17px;
}
.main-ip-info__ip-data .ip-data__row:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.main-ip-info__ip-data .ip-data__row .ip-data__col_param {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
  flex-basis: 115px;
  flex-shrink: 0;
  flex-grow: 0;
  /* padding-left: 11px; */
  overflow: hidden;
}
.main-ip-info__ip-data .ip-data__row .ip-data__col_param > div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-ip-info__ip-data .ip-data__row .ip-data__col_icon {
  width: 20px;
  margin-right: 12px;
  color: #5ca5c3;
  display: flex;
  align-items: center;
}
.main-ip-info__ip-data .ip-data__row .ip-data__col_value {
  display: flex;
  align-items: center;
  overflow: auto;
}
.main-ip-info__ip-data .ip-data__row .ip-data__col_value > div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-ip-info__ip-data .ip-data__row .ip-data__col_value > div > span {
  text-overflow: ellipsis;
}
/* yalala */
.finger-prints {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}
.list-items {
  padding: 15px 0;
}
.list-items .left img {
  width: 60px;
  height: 60px;
}
.list-items .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}
.list-items .right .title {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}
.list-items .right .prints-intro {
  font-size: 14px;
  color: #71818f;
  margin-bottom: 0.25rem;
}
.list-items .right span {
  font-size: 16px;
  color: #42b983;
  font-weight: bold;
}

.ip-show {
  padding: 60px 20px;
  display: flex;
  justify-content: center;
}
.introduce {
  display: flex;
  padding-bottom: 4px;
  font-weight: 700;
}
.title {
  font-size: 24px;
  color: #fff;
  flex: 1;
}
.ip-country {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
}
.ip {
  padding-bottom: 10px;
  border-bottom: 2px solid #4a5e70;
  text-align: center;
}
.ip h1 {
  font-size: 48px;
  color: #fff;
  font-weight: 600;
}
.safe-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.safe {
  display: flex;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  padding-top: 10px;
  text-decoration: none;
}
.safe:hover {
  color: #fff;
}
.switch-box {
  height: 30px;
  width: 60px;
  background: #e6e6e6;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.ip-progress {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}
.switch-button {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #fff;
  margin-left: 4px;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
}
.bg {
  height: 50px;
  max-width: 1200px;
  width: 100%;
  padding: 0 6px;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.bar {
  height: 38px;
  background: hsla(0, 0%, 100%, 0.3);
  width: 100%;
  border-radius: 38px;
  position: relative;
}
.bar-show {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 36px;
  color: #fff;
}
.bar-rate {
  height: 38px;
  border-radius: 38px;
  max-width: 100%;
  overflow: hidden;
}
.bar-show .bar-show-init {
  flex: 1;
  padding: 0 15px;
  display: flex;
  font-size: 14px;
}
.bar-show .bar-show-init .icon img {
  width: 30px;
}
.bar-show .tips {
  flex: 1;
  text-align: center;
}
.button {
  border: none;
  background: none;
  color: #fff;
}

.container-cus {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}
/* Footer */
.footer {
  background: #06060b;
  padding: 30px 0 30px 0;
  color: #60606d;
}
.footer .footer-init {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 20px;
}
.footer-init .footer-left {
  width: 60%;
}
.footer-init .footer-right {
  width: 40%;
}
.footer-right {
  padding-left: 40px;
}
.footer-right h2 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}
.footer-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-right a {
  color: #60606d;
  margin-top: 10px;
  margin-right: 10px;
}
.section_ip-check {
  padding-top: 30px;
}
.card__col.card__col_value {
  padding-left: 20px;
}
.ip-check__card {
  padding: 35px 16px 28px 50px;
  margin-bottom: 30px;
  min-height: 120px;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
}
.ip-check__card .card__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.ip-check__card .lang {
  margin-right: 50px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
}
.ip-check__card .card__data .card__row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 30px;
}
.ip-check__card .card__data .card__row .card__col {
  overflow: hidden;
  position: relative;
  padding-right: 26px;
}
.ip-check__card .card__data .card__row .card__col.card__col_param {
  width: 190px;
  white-space: nowrap;
  flex-shrink: 0;
  font-weight: 300;
  color: #097969;
}
.ip-check__card .card__data .card__row .card__col.card__col_value {
  width: 100%;
  flex-shrink: 2;
  color: #000;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
}
.ip-check__card .card__data.card__data_with-overflow .card__col:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white, white);
}
.enabled-status {
  width: 8px;
  height: 8px;
  background-color: #e8504f;
  border-radius: 4px;
  display: inline-block;
  flex-shrink: 0;
}
.enabled-status:not(.disabled) {
  background-color: #1fb74c;
}
.enabled-status__wrapper {
  display: flex;
  align-items: center;
}
.ip-check__card .enabled-status__wrapper .cont:not(.enabled-status) {
  margin-left: 10px;
}
.progress-tips {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  /* margin-top: -30px; */
  padding: 30px 0;
}
.progress-tips ul {
  list-style-type: none;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px;
}
.progress-tips ul li {
  display: flex;
  padding-bottom: 20px;
}

.progress-tips ul li .percent {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 8px;
  background: hsla(0, 0%, 100%, 0.2);
  font-size: 18px;
  margin-right: 20px;
}
.progress-tips ul li h2 {
  font-size: 20px;
  padding-bottom: 5px;
}
.progress-tips ul li {
  font-size: 14px;
}
