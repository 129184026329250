@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {

}

* {
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

html {

}

body {
    font-family: 'Open Sans', sans-serif;
}